jQuery(function ($) {
    "use strict";

    $('.emoji-hover').hover(        
        function(){ $(this).find('i.far').removeClass('fa-grin').addClass('fa-grin-stars') },
        function(){ $(this).find('i.far').removeClass('fa-grin-stars').addClass('fa-grin') }
    );

    $('.carrossel-historias').slick({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        variableWidth: true,
        prevArrow: '<button type="button" class="slick-prev" aria-label="Anterior"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next" aria-label="Próximo"><i class="fas fa-chevron-right"></i></button>',
    });

    $('.carrossel-outras-historias').slick({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        variableWidth: true,
        prevArrow: '<button type="button" class="slick-prev" aria-label="Anterior"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next" aria-label="Próximo"><i class="fas fa-chevron-right"></i></button>',
    });

    var itemCurrent;
    var itemTotals;

    $('.slide-historia').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
        
        var i = (currentSlide ? currentSlide : 0) + 1;

        var controles = $('.container-controles');
        var currentPage = $('#control-num');
        var totalsPage = $('#control-tot');

        itemCurrent = i;
        itemTotals = slick.slideCount;

        if(i == itemTotals){ $('#control-next, #control-last').addClass('disable'); }else{ $('#control-next, #control-last').removeClass('disable');  }

        currentPage.text(itemCurrent);
        totalsPage.text(itemTotals);

    });


    $('.slide-historia').slick({
        dots: false,
        arrows: false,
        infinite: false,
        speed: 1500,
        initialSlide: 1,
        adaptiveHeight: true
    });

    $('#control-first:not(.disable)').on('click', function(e){
        e.preventDefault();
        $('.slide-historia').slick('slickGoTo', 0);
        $("html, body").animate({ scrollTop: 0 }, 600);
    });
    $('#control-prev:not(.disable)').on('click', function(e){
        e.preventDefault();
        $('.slide-historia').slick('slickPrev');
        $("html, body").animate({ scrollTop: 0 }, 600);
    });
    $('#control-next:not(.disable)').on('click', function(e){
        e.preventDefault();
        $('.slide-historia').slick('slickNext');
        $("html, body").animate({ scrollTop: 0 }, 600);
    });
    $('#control-last:not(.disable)').on('click', function(e){
        e.preventDefault();
        $('.slide-historia').slick('slickGoTo', itemTotals);
        $("html, body").animate({ scrollTop: 0 }, 600);
    });

    
    
      
    
    /* =Mask jQuery
    -------------------------------------------------------------- */
    $('.mask-date').mask('00/00/0000');
    $('.mask-time').mask('00:00:00');
    $('.mask-dateTime').mask('00/00/0000 00:00:00');
    $('.mask-cep').mask('00.000-000');
    $('#calc_shipping_postcode').mask('00000-000');
    $('.mask-cpf').mask('000.000.000-00');
    $('.mask-cnpj').mask('00.000.000/0000-00');
    $('.mask-money').mask('000.000.000.000.000,00', {reverse: true});
    var CelMaskBehavior = function (val) {
            return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        },
        celOptions = {
            onKeyPress: function(val, e, field, options) {
                field.mask(CelMaskBehavior.apply({}, arguments), options);
            }
        };

    $('.mask-tel').mask(CelMaskBehavior, celOptions);

});